<template>
  <transition>
    <div
      v-if="Content"
      class="full-fixed flex items-center justify-center"
    >
      <LogoAndContent :title="Content.title">
        <Countdown
          class="mb-8"
          :target="Content.eventDate"
          :daysLabel="Content.daysLabel"
          :minutesLabel="Content.minutesLabel"
          :hoursLabel="Content.hoursLabel"
          :secondsLabel="Content.secondsLabel"
        />
        <Cta
          v-if="ctaVisible"
          :href="$root.GenericLabels.maseratiUrl"
          target="_blank"
          rel="noopener"
          data-linktracking="cta:visit-site"
        >
          {{visitLabel}}
        </Cta>
      </LogoAndContent>
    </div>
    <Loader v-else />
  </transition>
</template>

<script>
import GetQueryLanguage from '@/utils/query-language'
import GetCountdown from '@/graphql/GetCountdown.gql'
import LogoAndContent from '@/molecules/LogoAndContent.vue'
import Countdown from '@/atoms/Countdown.vue'
import Cta from '@/atoms/Cta.vue'

export default {
  name: 'CountdownPage',
  components: { Countdown, Cta, LogoAndContent },
  apollo: {
    Content: {
      query: GetCountdown,
      variables () {
        return {
          status: 'PUBLISHED',
          variants: GetQueryLanguage(this.$language.current)
        }
      },
      result () {
        if (this.Content) {
          this.$root.preloader.visible = false
          this.$root.pushAnalytics('pageload', {
            pageInfo: {
              language: this.$language.current.substr(0, 2),
              pageName: 'grecale:countdown',
              vehicleName: 'grecale'
            }
          })
        }
      }
    }
  },
  computed: {
    visitLabel () {
      let label = ''
      switch (this.$language.current) {
        case 'it':
          label = 'Visita il nostro sito'
          break
        case 'es':
          label = 'Visita nuestra página'
          break
        case 'ja':
          label = 'サイトに行く'
          break
        case 'ko':
          label = '마세라티 웹사이트 방문하기'
          break
        default:
          label = 'Visit our site'
      }
      return label
    },
    ctaVisible () {
      let visible = true
      if (this.Content && this.Content.eventDate) {
        visible = new Date(this.Content.eventDate) - Date.now() > 1000 * 60 * 60 * 4
      }
      return visible
    }
  }
}
</script>

<style lang="scss" scoped>
.countdown {
  flex: 1 1 auto;
}
</style>
