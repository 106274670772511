<template>
  <div class="countdown" role="timer" aria-live="off" aria-atomic="true">
    <span class="item" :aria-hidden="days < 1">
      <span class="number">{{days.toString().padStart(2, '0')}}</span>
      <span class="label">{{daysLabel}}</span>
    </span>
    <span class="item" :aria-hidden="hours < 1">
      <span class="number">{{hours.toString().padStart(2, '0')}}</span>
      <span class="label">{{hoursLabel}}</span>
    </span>
    <span class="item" :aria-hidden="minutes < 1">
      <span class="number">{{minutes.toString().padStart(2, '0')}}</span>
      <span class="label">{{minutesLabel}}</span>
    </span>
    <span class="item" :aria-hidden="minutes > 1">
      <span class="number">{{seconds.toString().padStart(2, '0')}}</span>
      <span class="label">{{secondsLabel}}</span>
    </span>
  </div>
</template>

<script>
import delay from 'lodash/delay'

export default {
  name: 'Countdown',
  props: {
    target: { type: String, required: true },
    daysLabel: { type: String, required: true },
    minutesLabel: { type: String, required: true },
    hoursLabel: { type: String, required: true },
    secondsLabel: { type: String, required: true }
  },
  data () {
    return {
      now: new Date(),
      days: null,
      hours: null,
      minutes: null,
      seconds: null
    }
  },
  created () {
    this.endDate = new Date(this.target)
    this.updateNow()
  },
  methods: {
    updateValues (difference) {
      this.days = Math.floor(difference / (1000 * 60 * 60 * 24))
      this.hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      this.seconds = Math.floor((difference % (1000 * 60)) / 1000)
    },
    updateNow () {
      this.now = new Date()
      if (this.now > this.endDate) {
        this.updateValues(0)
        this.$emit('end')
        return
      }

      this.updateValues(this.endDate - this.now)
      this.timer = delay(this.updateNow, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.countdown {
  font-size: 0;
}

.item {
  position: relative;

  display: inline-block;

  min-width: 25%;

  text-align: center;

  @screen lg {
    min-width: 9vw;
  }

  > * {
    display: block;
  }

  &:not(:last-child)::after {
    position: absolute;
    top: 30%;
    right: 0;

    width: rem(1px);
    height: 40%;

    background: currentColor;

    content: '';
  }

  &:first-child {
    @screen lg {
      margin-left: -1.4vw;
    }
  }
}

.number {
  font-size: 9vw;
  line-height: 9vw;
  @screen lg {
    font-size: 5vw;
    line-height: 5vw;
  }
}

.label {
  font-size: rem(12px);
  line-height: 1;
  @screen xs {
    font-size: rem(16px);
  }
  @screen lg {
    font-size: 1.2vw;
    line-height: 1vw;
  }
}

html.mobile {
  @media (orientation: landscape) {
    .countdown {
      margin-bottom: rem(12px);
    }

    .number {
      font-size: 6vw;

      line-height: 1;
    }
  }
}
</style>
